import styled from "styled-components"


export const LayoutWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0 20px 40px 20px;
  text-align: center;
  font-size: 1.4rem; 
  font-family: Lobster Two; 
  position: relative; 


  @media screen and (max-width: 250px) {
  width: 100; 
  padding: 0; 
}
`
